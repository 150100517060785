<!--
 * @Author: SongYijie
 * @Date: 2020-09-09 17:41:34
 * @LastEditors: SongYijie
-->
<template>
  <div class="main has-footer bg-gray">
    <div class="wrap">
      <task-list state="1" pageSize="5"></task-list>
    </div>
  </div>
</template>

<script>
  import taskList from "@components/task/task-list"
  export default {
    components: {
      taskList
    }
  }
</script>

<style lang='less' scoped>
.wrap{
    top: 0;
    bottom: 55px;
    left: 0;
    right: 0;
    position: absolute;
    overflow: scroll;
    margin-top: 15px;
}
  ul.task-list {
    padding: 0 20px;
  }
</style>
